<template>
  <div class="page_container">
    <div>
      <div class="code_input">
        <div class="input_wrapper">
          <input
            @keyup.enter="search"
            type="text"
            class="form-control"
            id="search-input"
            name="keyword"
            :placeholder="
              isCasinoSearch
                ? $t('searchForCasinoGames')
                : $t('searchForGamesLeaguesEvents')
            "
            :aria-label="
              isCasinoSearch
                ? $t('searchForCasinoGames')
                : $t('searchForGamesLeaguesEvents')
            "
            v-model="to_search"
            style="height: 54px"
          />
          <button @click="handleCancel" class="cancel" v-show="isCancel">
            <CancelIcon />
          </button>
        </div>
      </div>
      <div v-if="isCasinoSearch">
        <GamesCasino :allgamesdata="casinoGamesData" :isLoading="loading" />
      </div>
      <div v-else>
        <Games
          v-bind:search="keywords"
          v-bind:searchable="searchable"
          v-on:search-results="handleSearchResults"
          v-bind:reset="reset"
        >
        </Games>

        <div class="container">
          <div>
            <div v-if="keywords.length < 1">
              <NoData
                :title="$t('searchForGames')"
                :description="$t('searchGames')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Games from "../../components/Games.vue";
import NoData from "../../components/ui/NoData.vue";
import casino from "@/services/casino";
import GamesCasino from "../games/components/GamesCasino.vue";
import CancelIcon from "../../components/icons/CancelIcon.vue";

export default {
  name: "Search",
  components: {
    Games,
    NoData,
    GamesCasino,
    CancelIcon,
  },
  data: function () {
    return {
      reset: false,
      searchable: true,
      to_search: "",
      keywords: "",
      sub_page: "",
      results: "",
      busy: false,
      no_results: false,
      loading: false,
      casinoGamesData: [],
      currentPage: 1,
      last_page: 1,
      per_page: 10,
      is_busy: false,
    };
  },
  mounted() {
    this.$store.dispatch("setCurrentPage", "search");
    this.getBonus();
    this.$store.dispatch("resetAllGames");
  },
  computed: {
    isCasinoSearch() {
      return this.$store.state.searchType === "games";
    },
    betslip_count: function () {
      return this.$store.state.betslip.total;
    },
    current_sub_page: function () {
      return this.$store.state.current_sub_page;
    },
    current_page: function () {
      return this.$store.state.current_page;
    },
    shouldDisplayTrivia() {
      const now = new Date();
      const startDate = new Date("2024-05-05T13:06:00");
      const endDate = new Date("2024-05-05T16:30:00");
      return now >= startDate && now <= endDate;
    },
    isCancel() {
      return this.to_search.length > 0;
    },
  },
  methods: {
    handleSearchResults(results) {
      this.results = results.length > 0;
      this.no_results = results.length === 0;
    },
    getKey: function (fixture, index) {
      if (Array.isArray(fixture)) {
        var currentFixture = fixture[0];
      } else {
        currentFixture = fixture;
      }

      var prefix =
        currentFixture === undefined || currentFixture.match_id === undefined
          ? index
          : currentFixture.match_id;
      return Math.random()
        .toString(10)
        .replace("0.", "fixture-id-" + prefix + "-");
    },

    setSubPage: function (page) {
      this.$store.dispatch("setCurrentSubPage", page);
      this.sub_page = page;
    },
    geSubPageActiveClass: function (subpage) {
      return subpage === this.sub_page ? "active" : "";
    },
    setSport: function (sportID) {
      this.$store.dispatch("setSportID", sportID);
    },
    search: function () {
      var vm = this;
      this.reset = false;

      if (this.isCasinoSearch) {
        this.handleCasinoSearch();
      } else {
        vm.keywords = vm.to_search;
      }
    },
    handleCasinoSearch() {
      this.getCasinoGames();
    },
    async getCasinoGames() {
      const vm = this;
      this.loading = true;

      try {
        const path = `/games?page=${vm.currentPage}&per_page=${vm.per_page}&name=${vm.to_search}`;
        const resp = await casino.get(path);

        if (resp.data.data === null) {
          this.casinoGamesData = [];
          return;
        }
        this.casinoGamesData = resp.data.data;
      } catch (error) {
        this.loading = false;
      } finally {
        vm.is_busy = false;
        this.loading = false;
      }
    },
    handleCancel() {
      this.to_search = "";
      this.reset = true;
      this.casinoGamesData = [];
    },
  },
  watch: {
    to_search: function () {
      if (this.to_search.length > 0) {
        this.search();
      } else {
        this.casinoGamesData = [];
      }
    },
  },
};
</script>

<style scoped>
.code_input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  margin-top: 8px;
}

.code_input button {
  width: 28%;
  margin-top: -8px;
}

.input_wrapper {
  width: 100%;
  position: relative;
}
.input_wrapper input {
  height: 47px;
  background-color: var(--background-color);
}

.input_wrapper .cancel {
  position: absolute;
  top: 22px;
  right: 14px;
  color: var(--text-color);
  outline: none;
  background-color: transparent;
  border: none;
  font-size: 18px;
  width: 20px;
  height: 35px;
}
</style>
